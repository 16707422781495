import { Modal, Typography } from 'antd';
import {
  json,
  Link,
  LoaderFunctionArgs,
  useLoaderData,
} from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import { differenceInSeconds } from 'date-fns';
import React, { useEffect } from 'react';
import { gql } from '@apollo/client';

import { requireUser, useAuth } from '../../contexts/auth';

import Entry from '../../assets/images/entry.png';
import Exit from '../../assets/images/exit.png';
import Compare from '../../assets/images/comparison.png';
import './styles.css';
import client from '../../services/apollo';
import { Charts } from './Charts';
import { CashbackModal } from './CashbackModal';

type Decoded = {
  iat: number;
};

const handleOpenWarningModal = (metreage: number) => {
  const token = localStorage.getItem(`${process.env.REACT_APP_SESSION_KEY}`);

  const decoded: Decoded | null = token ? jwtDecode(token) : null;
  const signInDate = decoded ? decoded.iat * 1000 : null;

  if (signInDate) {
    const diff = differenceInSeconds(Date.now(), signInDate);
    if (diff >= 1 && diff < 15 && metreage < 500) {
      setTimeout(() => {
        Modal.warning({
          title: <Typography.Title level={3}>Atenção</Typography.Title>,
          content: (
            <Typography.Title level={4}>
              O seu saldo está acabando! Atual: {metreage} m²
            </Typography.Title>
          ),
        });
      }, 1000);
    }
  }
};

const GET_REPORT_GENERAL = gql`
  query GetReportContestationGeneral($enterpriseId: Float!) {
    getReportContestationGeneral(enterpriseId: $enterpriseId) {
      percentContestation
      percentOrderService
    }
  }
`;

const GET_DIVERGENCE_REPORT = gql`
  query GetReportDivergenceAboutContestation($enterpriseId: Float!) {
    getReportDivergenceAboutContestation(enterpriseId: $enterpriseId) {
      percenteOrderService
      percenteDivergenceProceed
    }
  }
`;

const GET_BALANCE = gql`
  query GetBalance($enterpriseId: String!) {
    plan: getCurrentPlanEnterpriseClient(enterpriseId: $enterpriseId) {
      balance_metreage
    }
  }
`;

export let loader = async ({ request }: LoaderFunctionArgs) => {
  const user = requireUser();

  const balanceQueryPromise = client.query<GetPlan>({
    query: GET_BALANCE,
    variables: { enterpriseId: user.enterpriseId },
    context: {
      uri: process.env.REACT_APP_VISTORIA_SYNC_GRAPHQL,
    },
  });
  const reportGeneralQueryPromise = client.query<GetGeneralReport>({
    query: GET_REPORT_GENERAL,
    variables: { enterpriseId: Number(user.enterpriseId) },
    context: {
      uri: process.env.REACT_APP_VISTORIA_SYNC_GRAPHQL,
    },
  });

  const reportDivergenceQueryPromise = client.query<GetDivergenceReport>({
    query: GET_DIVERGENCE_REPORT,
    variables: { enterpriseId: Number(user.enterpriseId) },
    context: {
      uri: process.env.REACT_APP_VISTORIA_SYNC_GRAPHQL,
    },
  });

  const [balanceQuery, reportGeneralQuery, reportDivergenceQuery] =
    await Promise.all([
      balanceQueryPromise,
      reportGeneralQueryPromise,
      reportDivergenceQueryPromise,
    ]);

  return json({
    plan: balanceQuery.data.plan,
    getReportContestationGeneral:
      reportGeneralQuery.data.getReportContestationGeneral,
    getReportDivergenceAboutContestation:
      reportDivergenceQuery.data.getReportDivergenceAboutContestation,
  });
};

export type DashboardLoaderData = GetGeneralReport &
  GetDivergenceReport &
  GetPlan;

interface GetGeneralReport {
  getReportContestationGeneral: {
    percentContestation: number;
    percentOrderService: number;
  };
}

interface GetDivergenceReport {
  getReportDivergenceAboutContestation: {
    percenteOrderService: number;
    percenteDivergenceProceed: number;
  };
}

interface GetPlan {
  plan: {
    balance_metreage: number;
  };
}

export function Component() {
  const { user } = useAuth();
  const { plan } = useLoaderData() as DashboardLoaderData;

  useEffect(() => {
    const metreage = plan.balance_metreage;
    if (metreage) {
      handleOpenWarningModal(metreage);
    }
  }, [plan.balance_metreage]);

  const [open, setOpen] = React.useState(false);
  React.useEffect(() => {
    if (user) {
      setOpen(!!user.showRecommendation);
    }
  }, [user]);

  const markCashbackShown = () => {
    try {
      const key = process.env.REACT_APP_USER_PROFILE_KEY ?? '';
      const userString = localStorage.getItem(key) ?? '';
      const user = JSON.parse(userString);
      user.showRecommendation = false;
      localStorage.setItem(key, JSON.stringify(user));
    } finally {
    }
  };

  return (
    <section className="p-4 mr-auto">
      <CashbackModal
        open={open}
        onClose={() => {
          markCashbackShown();
          setOpen(false);
        }}
      />

      <Typography.Title level={2} className="text-brand">
        Bem vindo(a), {user?.name}
      </Typography.Title>
      <section className="py-3 px-4 border border-solid bg-white rounded">
        <Typography.Title level={4}>Solicitar vistoria</Typography.Title>
        <div className="dashboard-actions-container">
          {orderTypes.map(({ name, icon, finality, width }) => (
            <Link
              to={`/new-inspection?finality_inspection=${finality}`}
              key={name}
            >
              <a
                href="inherit"
                className="flex flex-col items-center justify-around h-32 rounded p-3 border border-gray-500 border-solid hover:bg-gray-100"
                style={{ flex: '1 1 300px' }}
              >
                <img
                  src={icon}
                  alt={name}
                  className="block mx-auto mt-2 mb-2 text-3xl max-w-full"
                  style={{ width }}
                />
                <Typography.Title
                  level={4}
                  className="font-semibold text-center"
                >
                  Vistoria de {name}
                </Typography.Title>
              </a>
            </Link>
          ))}
        </div>
      </section>
      <section className="py-2 mt-3">
        <Typography.Title level={3} className="text-brand">
          Desempenho das suas vistorias
        </Typography.Title>
        <Charts />
      </section>
    </section>
  );
}
Component.displayName = 'Dashboard';

const orderTypes = [
  { name: 'Entrada', finality: 'entry', icon: Entry, width: 40 },
  { name: 'Saída', finality: 'exit', icon: Exit, width: 40 },
  { name: 'Comparação', finality: 'comparison', icon: Compare, width: 40 },
];

import { format, parseISO } from 'date-fns';

const conformValue2Mask = (value = '', mask: string) => {
  const formatted: string[] = [];

  if (value.length !== mask.length) {
    return value;
  } else {
    mask.split('').forEach((char: string, index: number) => {
      if (char === '#') {
        formatted[index] = value[index];
      } else {
        formatted[index] = char;
      }
    });

    return formatted.join('');
  }
};

const dateformatter = (
  date: string | Date,
  dateFormat = 'dd/MM/yyyy HH:mm'
): string => {
  if (!date) return ''
  return format(typeof date === 'string' ? parseISO(date) : date, dateFormat);
};

export { conformValue2Mask, dateformatter };

import { DatePicker, Form, Modal, ModalProps, notification } from "antd";
import moment from "moment";
import { useRef, useState } from "react";
import OrderService from "../../common/interfaces/OrderService";
import { SCHEDULE_TIME_COMBINATIONS } from "../../constants/schedule";
import { disabledScheduleTime } from "../../rules";
import {
  isWeekend,
  isSameDay,
  add,
  addDays,
  isBefore
} from "date-fns";
import locale from "antd/es/date-picker/locale/pt_BR";

import { rescheduleOrderService } from "../../services/http/order-service";
import { useUnavailableDates as useBlockedDates } from "../OrderService/RequestSteps/InspectionInfo/useUnavailableDates.hook";

export function useRescheduleModal() {
  const [isOpen, setOpen] = useState(false);

  const open = () => setOpen(true);
  const close = () => setOpen(false);

  return { isOpen, open, close };
}

interface Props extends Pick<ModalProps, "visible"> {
  data: OrderService;
  onClose: () => void;
  onSave?: () => void;
}

export function RescheduleModal({ data, onClose, onSave, ...props }: Props) {
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);

  const hasSelectedTime = useRef(false);
  const setHasSelectedTime = (value: boolean) => {
    hasSelectedTime.current = value;
  };

  const handleSave = () => {
    setLoading(true);

    const formValues = form.getFieldsValue();

    if (hasSelectedTime.current) {
      formValues["dateRescheduleWithTime"] = formValues.dateReschedule;
    }

    rescheduleOrderService({
      ...formValues,
      orderServiceId: String(data.id),
    })
      .then(() => {
        notification.success({
          message: "Reagendado com sucesso!",
        });
        onSave?.();
        onClose();
      })
      .catch((error) => {
        console.log(error?.toString?.());
      })
      .finally(() => setLoading(false));
  };

  const onTimeChange = (momentDate: moment.Moment | null) => {
    const hour = momentDate?.hour();
    const combination = SCHEDULE_TIME_COMBINATIONS.find((c) => c.hour === hour);
    if (combination && data?.finality_inspection !== "location_entry") {
      setHasSelectedTime(true);
      form.setFieldsValue({
        ...form.getFieldsValue(),
        dateReschedule: momentDate?.set({
          minute: combination.minute,
        }),
      });
    } else {
      setHasSelectedTime(false);
    }
  };

  const blockedDatesQuery = useBlockedDates();
  const shouldShowTime = data?.finality_inspection !== "location_entry";

  return (
    <Modal
      {...props}
      onCancel={onClose}
      title={`Reagendar Ordem de Serviço ${data.number_request_internal}`}
      confirmLoading={loading}
      okText="Salvar"
      onOk={handleSave}
    >
      <Form
        form={form}
        layout="vertical"
        className="d-flex justify-content-center items-center"
      >
        <Form.Item label="Selecione a nova data" name="dateReschedule">
          <DatePicker
            onChange={onTimeChange}
            onSelect={onTimeChange}
            disabledTime={disabledScheduleTime}
            showNow={false}
            showHour
            showMinute
            showSecond={false}
            format={shouldShowTime ? "DD/MM/YYYY HH:mm" : "DD/MM/YYYY"}
            locale={locale}
            placeholder="Selecione a data"
            inputReadOnly
            disabledDate={(date) => {
              const rawDate = date.toDate();

              const isWeekendDate = isWeekend(rawDate);

              if (
                blockedDatesQuery.data?.unavailableDates?.some(
                  (d) =>
                    d.date &&
                    isSameDay(
                      add(Date.parse(d.date), { hours: 3 }),
                      date.toDate()
                    )
                )
                // blockedDatesQuery.data?.blockedDates?.some(
                //   d =>
                //     d.dateBlock &&
                //     isSameDay(
                //       add(Date.parse(d.dateBlock), { hours: 3 }),
                //       date.toDate()
                //     )
                // )
              ) {
                return true;
              }

              if (isWeekendDate) return true;
              else {
                const today = new Date();
                const isFriday = today.getDay() === 5;
                const isSaturday = today.getDay() === 5;
                const isSunday = today.getDay() === 5;

                const todayHour = today.getHours();
                const isFridayEqualAfter15Hours = isFriday && todayHour >= 15;
                const isSaturdayEqualAfter15Hours =
                  isSaturday && todayHour >= 15;
                const isSundayEqualAfter15Hours = isSunday && todayHour >= 15;

                const isTodayEqualAfter15Hours = todayHour >= 15;
                let dayToadd = 0;
                if (isFridayEqualAfter15Hours) dayToadd = 3;
                if (isSaturdayEqualAfter15Hours) dayToadd = 2;
                if (isSundayEqualAfter15Hours) dayToadd = 1;

                const added = addDays(
                  today,
                  isFridayEqualAfter15Hours ||
                    isSaturdayEqualAfter15Hours ||
                    isSundayEqualAfter15Hours
                    ? dayToadd
                    : isTodayEqualAfter15Hours
                    ? dayToadd + 1
                    : 0
                );
                if (isBefore(rawDate, added)) return true;
              }

              return false;
            }}
            // showTime={{ defaultValue: moment('08:00:00', 'HH:mm:ss') }}
            showTime={
              shouldShowTime
                ? { defaultValue: moment("08:00:00", "HH:mm:ss") }
                : false
            }
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}

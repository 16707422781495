import { Menu, Modal, notification } from 'antd';
import { Link } from 'react-router-dom';
import React from 'react';

import OrderService from '../../common/interfaces/OrderService';
import { differenceInHours } from 'date-fns';
import { axiosInstanceSync } from '../../services/axios';
import { useStatement } from '../../contexts/statement';
import { RescheduleModal, useRescheduleModal } from './RescheduleModal';
import { isTomorrow } from '../../utils/date';

interface Props {
  status: string;
  id: string;
  data: OrderService | undefined;
  refetch: () => any;
}

export default function StatusMenu({ status, id, data, refetch }: Props) {
  const { setStatement } = useStatement();
  const handleUpdateStatement = () => setStatement(true);

  const rescheduleModal = useRescheduleModal();

  const [triggeredReport, setTriggeredReport] = React.useState(false);

  if (!data) return null;

  const contestation = data?.inspection?.contestation;

  const dateToday = new Date();
  const orderServiceDateInspectionAfter7Days = new Date(data.date_inspection);
  orderServiceDateInspectionAfter7Days.setDate(
    orderServiceDateInspectionAfter7Days.getDate() + 7
  );
  orderServiceDateInspectionAfter7Days.setHours(24);
  orderServiceDateInspectionAfter7Days.setMinutes(0);
  orderServiceDateInspectionAfter7Days.setHours(0);

  const dateInspection =
    data?.date_inspection ?? data?.date_schedule_inspection;
  const dateLimitToReSchedule = new Date(dateInspection);
  dateLimitToReSchedule.setHours(dateLimitToReSchedule.getHours() - 24);

  const orderServiceDateInspection = new Date(data.date_inspection);

  function generateReport(
    orderService: OrderService,
    withoutPhoto: boolean = false
  ): void {
    setTriggeredReport(true);
    notification.info({
      key: 'report_info',
      message:
        'O seu relatório está sendo gerado, estará disponível em alguns instantes.',
    });

    axiosInstanceSync
      .post(`/api/order-service/${orderService.id}/generate-report-order-service`, {
        withoutPhoto,
      }, {baseURL: process.env.REACT_APP_API_VISTORIA})
      .then(() => {
        notification.close('report_info');
        notification.success({
          message: `Geração do relatório foi enviado com sucesso. Aguarde alguns segundos e atualize a página.`,
        });
      })
      .catch(() => {
        notification.close('report_info');
        notification.error({
          message:
            'Houve um erro ao gerar o relatório. Tente novamente mais tarde.',
        });
      });
  }

  const handleCancel = () => {
    const { date_solicitation, date_inspection } = data;

    // const diffInHoursDateNowToDateInspection = differenceInHours(Date.now(), new Date(date_inspection))

    let message = {
      title: 'Cancelar pedido',
      content: 'Tem certeza que deseja cancelar?',
      okCancel: true,
      cancelText: 'Voltar',
      okText: 'Confirmar',
      onOk: () => {
        axiosInstanceSync
          .post(`/client/order-service/${id}/cancel`)
          .then(() => {
            notification.success({
              message: 'O pedido foi cancelado com sucesso!',
            });
            handleUpdateStatement();
            refetch();
          });
      },
    };

    if (isTomorrow(new Date(date_inspection))) {
      if (differenceInHours(Date.now(), new Date(date_solicitation)) > 2) {
        Modal.warn({
          ...message,
          content:
            'A solicitação foi realizada há mais de uma 2h, o cancelamento acarretará em consumo.',
          onOk: () => {
            axiosInstanceSync
              .post(`/client/order-service/${id}/cancel`)
              .then(() => {
                refetch();
                handleUpdateStatement();
                notification.success({
                  message: 'O pedido foi cancelado com sucesso!',
                });
              });
          },
        });
        return
      }
    }

    Modal.warn(message);
  };

  return (
    <Menu>
      <Menu.Item key="details">
        <Link to={`/my-requests/${id}`}>Detalhes do pedido</Link>
      </Menu.Item>
      {orderServiceDateInspectionAfter7Days >= dateToday && (
        <Menu.Item key="edit">
          <Link to={`/my-requests/${id}/edit`}>Editar pedido</Link>
        </Menu.Item>
      )}
      {(status === 'Finalizado' ||
        status === 'Contestado' ||
        status === 'Respondido') && (
        <>
          <Menu.Item
            disabled={
              !data?.inspection?.pathReportOrderService || triggeredReport
            }
          >
            <a
              href={data?.inspection?.pathReportOrderService}
              target="_blank"
              rel="noreferrer"
            >
              Visualizar relatório
            </a>
          </Menu.Item>
          <Menu.Item
            disabled={!data?.inspection?.pathReportOrderServiceWithoutPhoto}
          >
            <a
              href={data?.inspection?.pathReportOrderServiceWithoutPhoto}
              target="_blank"
              rel="noreferrer"
            >
              Visualizar relatório sem foto
            </a>
          </Menu.Item>
          <Menu.Item
            onClick={() => {
              const reportWithoutPhoto = true;
              generateReport(data, reportWithoutPhoto);
            }}
          >
            Gerar relatório sem foto
          </Menu.Item>
        </>
      )}
      {status === 'Agendado' && (
        <>
          <Menu.Item key="cancel" onClick={handleCancel}>
            Cancelar pedido
          </Menu.Item>
          {orderServiceDateInspection >= dateLimitToReSchedule &&
            !data.inspection?.dateFinish && (
              <Menu.Item key="schedule" onClick={rescheduleModal.open}>
                Reagendar pedido
              </Menu.Item>
            )}
          <RescheduleModal
            data={data}
            visible={rescheduleModal.isOpen}
            onClose={rescheduleModal.close}
            onSave={refetch}
          />
        </>
      )}
      {status === 'Entregue' && <Menu.Item key="contest">Contestar</Menu.Item>}
      {(status === 'Contestado' ||
        status === 'Respondido' ||
        contestation?.path) && (
        <>
          <Menu.Item key="view3">
            <a
              href={data?.inspection?.contestation?.path}
              target="_blank"
              rel="noreferrer"
            >
              Visualizar contestação
            </a>
          </Menu.Item>
        </>
      )}
      {(status === 'Contestado' ||
        status === 'Respondido' ||
        status === 'Finalizado') && (
        <>
          <Menu.Item key="view">
            <a
              href={`https://contestacao.bhvp.com.br/orderservice/${data?.id}/contestation`}
              target="_blank"
              rel="noreferrer"
            >
              Acessar contestação
            </a>
          </Menu.Item>
          <Menu.Item key="view1">
            <button
              onClick={() => {
                navigator.clipboard.writeText(
                  `https://contestacao.bhvp.com.br/orderservice/${data?.id}/contestation`
                );
                notification.success({
                  message: 'Link copiado com sucesso!',
                });
              }}
            >
              Copiar link da contestação
            </button>
          </Menu.Item>
        </>
      )}
    </Menu>
  );
}
